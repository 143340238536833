<!-- 地图坐标点列表 -->
<template>
  <div class="mapBox">
    <div class="header">
      <img class="header-logo-ci" src="@/assets/image/zhfe/zhfCI.png" />
      <img class="header-logo" src="@/assets/image/zhfe/zhfelogo.png" />
      <span class="header-txt">智惠妇儿</span>
      <!-- 关闭 刷新 -->
      <div class="idx-middlesx-box3">
        <div class="idx-middle-1">
          <img
            class="fh"
            src="@/assets/image/zhfe/return.png"
            @click="goBack"
          />
          <img
            class="sx"
            src="@/assets/image/zhfe/icon/sx.png"
            alt=""
            @click="refresh"
          />
        </div>
      </div>
    </div>
    <!-- 时间 -->
    <div class="time-box">
      <Date></Date>
    </div>
    <div class="page_title">优秀案例管理</div>
    <div class="content">
      <div class="map-seach">
        <div class="seach-left">
          <div class="seach-page">
            <el-input
              v-model="yxalQuery.dataName"
              placeholder="请输入主标题搜索"
            ></el-input>
          </div>
          <div class="btn_box">
            <div class="query_btn" @click="_getWomanMeetList">查询</div>
            <div class="query_btn" @click="onReset">重置</div>
          </div>
        </div>
        <div class="seach-right">
          <div class="query_btn" @click="addMap">新增</div>
        </div>
      </div>
      <div class="map-table">
        <el-table
          :data="tableData"
          height="100%"
          row-class-name="rowName"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            label="序号"
            width="60"
          ></el-table-column>
          <el-table-column
            prop="dataName"
            label="主标题"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="dataDetailed"
            label="副标题"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="dataField1"
            label="优秀议事项目"
            width="260"
          ></el-table-column>
          <el-table-column
            prop="dataField3"
            label="项目特色"
            width="260"
          ></el-table-column>
          <el-table-column
            prop="dataField2"
            label="议事会成员"
            width="260"
          ></el-table-column>
          <el-table-column
            prop="dataField4"
            min-width="200px"
            label="议事项目推进模式"
          >
            <template slot-scope="scope">
              <div
                class="item_right"
                v-html="
                  scope.row.dataField4
                    ? scope.row.dataField4.replace(/\n/gm, '<br>')
                    : ''
                "
              ></div>
            </template>
          </el-table-column>
          <el-table-column label="图片" width="80">
            <template slot-scope="scope">
              <el-button @click="lookMap(scope.row)" type="text" size="small"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column label="操作" width="130">
            <template slot-scope="scope">
              <el-button @click="_updateMap(scope.row)" type="text" size="small"
                ><i class="el-icon-edit"></i> 修改</el-button
              >
              <el-button @click="delMap(scope.row)" type="text" size="small"
                ><i class="el-icon-delete"></i> 删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination_box">
        <el-pagination background layout="total" :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 图片查看 -->
    <el-dialog
      :visible.sync="imgshow"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="51vw"
      center
    >
      <div class="dialogTitleBox">
        <div class="dialogTitle">图片查看</div>
        <div class="dialogClose" @click="imgshow = false"></div>
      </div>
      <div class="dialogMainImg">
        <div v-for="(item, i) in imgList" :key="i" class="imgbox">
          <img :src="item" />
        </div>
      </div>
    </el-dialog>
    <!-- 新增 -->
    <el-dialog
      :visible.sync="addMapshow"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="50vw"
      center
    >
      <div class="dialogTitleBox">
        <div class="dialogTitle">{{ mapTitle }}</div>
        <div class="dialogClose" @click="closeDialog"></div>
      </div>
      <div class="mapdialogBox">
        <div class="map-item">
          <div class="map-label"><span>*</span>主标题</div>
          <div class="map-input">
            <input type="text" v-model="updateMapData.dataName" />
          </div>
        </div>
        <div class="map-item">
          <div class="map-label"><span>*</span>副标题</div>
          <div class="map-input">
            <input type="text" v-model="updateMapData.dataDetailed" />
          </div>
        </div>
        <div class="map-item">
          <div class="map-label"><span>*</span>优秀议事项目</div>
          <div class="map-input">
            <input type="text" v-model="updateMapData.dataField1" />
          </div>
        </div>
        <div class="map-item">
          <div class="map-label"><span>*</span>项目特色</div>
          <div class="map-input">
            <input type="text" v-model="updateMapData.dataField3" />
          </div>
        </div>
        <div class="map-item">
          <div class="map-label"><span>*</span>议事会成员</div>
          <div class="map-input">
            <input type="text" v-model="updateMapData.dataField2" />
          </div>
        </div>
        <div class="map-item">
          <div class="map-label"><span>*</span>议事项目推进模式</div>
          <div class="map-input">
            <input type="text" v-model="updateMapData.dataField4" />
          </div>
        </div>
        <div class="map-item textarea">
          <div class="map-label"><span>*</span>图片</div>
          <el-upload
            class="avatar-uploader"
            :action="uploadAction"
            :limit="10"
            list-type="picture-card"
            :file-list="uploadImglist"
            :on-remove="handleRemove"
            :on-exceed="handleExceed"
            :on-error="imgerror"
            :on-success="imgsuccess"
            accept=".jpeg,.jpg,.png"
            :before-upload="beforeAvatarUpload"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <div class="uploadTips">
          只能上传JPG/PNG格式文件，最多只能上传10张图片，单个图片不能超过150kb.
        </div>
        <div class="dialog_btn" style="display: block">
          <div style="margin-right: 50px" @click="closeDialog">取消</div>
          <div @click="onSubmit">确定</div>
        </div>
      </div>
    </el-dialog>
    <!-- 删除 -->
    <dialogAlert
      :dialogVisible="dialogVisible"
      :dialogData="dialogData"
      @closeDialog="closeDialogDel"
      @determine="determine"
    >
      <span slot="txt">{{ delDataTxt }}</span>
    </dialogAlert>
  </div>
</template>

<script>
import Date from "@/views/commonComponents/date.vue";
import { listData } from "@/api/dtdata.js";
import { listMap, addMap, updateMap, delMap } from "@/api/map";
import { getWomanMeetList, getNumber, add, update,del } from "@/api/womanMeet";
import dialogAlert from "@/views/homeImg/components/dialogAlert.vue";
export default {
  components: {
    Date,
    dialogAlert,
  },
  data() {
    return {
      tableData: [],
      queryParam: {
        remark: "",
        type: "",
        pageSize: 10,
        pageNum: 1,
      },
      total: 0,
      typeList: [],
      maptypeList: [], //地图类别
      loading: false,
      imgshow: false, //图片显示
      imgList: [], //图片列表
      addMapshow: false, //新增修改坐标
      mapTitle: "",
      uploadAction: "/prod-api/common/upload", //图片上传地址
      // imageUrl: '',
      uploadImglist: [], // 上传的图片
      updateMapData: {
        dataName: "",
        dataDetailed: "",
        dataField1: "",
        dataField3: "",
        dataField4: "",
        dataField2: "",
      },
      isupdate: false,
      dialogVisible: false, // 删除的弹窗
      dialogData: "", // 删除的数据
      delDataTxt: "", //删除的提示

      yxalQuery: {
        // 优秀案例
        dataModule: "fnysh",
        dataTyp: "fnysh-yxal",
        status: 0,
        dataName: "",
      },
      yxalList: [],
      yxalIndex: 0,
      yxalimageQuery: {
        // 优秀案例-图片
        dataModule: "fnysh-yxal-image",
        dataTyp: "",
      },
    };
  },
  methods: {
    async _updateMap(row) {
      console.log("编辑数据", row);
      this.mapTitle = "修改优秀案例";
      let islogin = this.islogin();
      if (islogin == 1) {
        this.updateMapData = {
          ...row,
        };
        // const {
        //   dataName,
        //   dataDetailed,
        //   dataField1,
        //   dataField3,
        //   dataField4,
        //   dataField2,
        // } = row;
        // this.updateMapData.dataName = dataName;
        // this.updateMapData.dataDetailed = dataDetailed;
        // this.updateMapData.dataField1 = dataField1;
        // this.updateMapData.dataField3 = dataField3;
        // this.updateMapData.dataField4 = dataField4;
        // this.updateMapData.dataField2 = dataField2;
        const { dataId } = row || {};
        this.yxalimageQuery.dataTyp = dataId;
        this.uploadImglist = []
        const { rows } = await getWomanMeetList(this.yxalimageQuery);
        console.log("返回图片数据", rows);
        rows.map((item) => {
          this.uploadImglist.push({
            dataTyp: item.dataTyp,
            url: item.dataDetailed,
          });
        });
        this.addMapshow = true;
        this.isupdate = true;
      }
    },
    async _getWomanMeetList() {
      const { rows, total } = await getWomanMeetList(this.yxalQuery);
      console.log("获取数据", rows);
      this.tableData = rows;
      this.total = total;
      this.loading.close();
    },
    // 关闭弹窗
    closeDialog() {
      this.addMapshow = false;
      this.updateMapData = {
        dataName: "",
        dataDetailed: "",
        dataField1: "",
        dataField3: "",
        dataField4: "",
        dataField2: "",
      };
      this.uploadImglist = [];
    },
    // 新增
    addMap() {
      let islogin = this.islogin();
      if (islogin == 1) {
        this.mapTitle = "新增优秀案例";
        this.uploadImglist = [];
        this.addMapshow = true;
        this.isupdate = false;
      }
    },
    closeDialogDel() {
      this.dialogVisible = false;
    },
    determine(datas) {
      del(datas.dataId)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this._getWomanMeetList();
          }
        })
        .finally(() => {
          this.dialogVisible = false;
        });
    },
    // 删除
    delMap(data) {
      let islogin = this.islogin();
      if (islogin == 1) {
        this.dialogVisible = true;
        this.delDataTxt = "您确定要删除 " + data.dataName + " 吗?";
        this.dialogData = data;
      }
    },
    // 查看图片
    async lookMap(data) {
      let islogin = this.islogin();
      if (islogin > 0) {
        let lodings = this.$loading({
          lock: true,
          text: "加载中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        this.imgList = [];
        console.log("獲取圖片", data);
        const { dataId } = data || {};
        this.yxalimageQuery.dataTyp = dataId;
        const { rows } = await getWomanMeetList(this.yxalimageQuery);
        console.log("返回图片数据", rows);
        this.imgshow = true;
        rows.map((item) => {
          this.imgList.push(
            item.dataDetailed
          );
        });
        lodings.close();
      }
    },
    //查询
    onQuery() {
      this.loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.tableData = [];
      listMap({
        mapName: this.queryParam.remark,
        mapTyp: this.queryParam.type,
        pageSize: this.queryParam.pageSize,
        pageNum: this.queryParam.pageNum,
      })
        .then((res) => {
          res.rows.forEach((item) => {
            this.maptypeList.forEach((typeitem) => {
              if (typeitem.dataDetailed == item.mapTyp) {
                item.typeName = typeitem.dataName;
              }
            });
            this.tableData.push(item);
          });
          this.total = res.total;
        })
        .finally(() => {
          this.loading.close();
        });
    },
    // 图片新增修改确认
    async onSubmit() {
      if (!this.updateMapData.dataName.trim()) {
        this.$message.warning("请输入主标题");
      } else if (!this.updateMapData.dataDetailed.trim()) {
        this.$message.warning("请输入副标题");
      } else if (!this.updateMapData.dataField1.trim()) {
        this.$message.warning("请输入优秀议事项目");
      } else if (!this.updateMapData.dataField3.trim()) {
        this.$message.warning("请输入项目特色");
      } else if (!this.updateMapData.dataField2.trim()) {
        this.$message.warning("请输入议事会成员");
      } else if (!this.updateMapData.dataField4.trim()) {
        this.$message.warning("请输入议事项目推进模式");
      } else if (!this.uploadImglist.length) {
        this.$message.warning("请上传图片");
      } else {
        this.loading = this.$loading({
          lock: true,
          text: "加载中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        if (this.isupdate) {
          let imgData = [];
          this.uploadImglist.forEach((item) => {
            imgData.push({
              dataTyp: this.yxalimageQuery.dataTyp,
              dataDetailed: item.url,
            });
          });
          const data = {
            ...this.updateMapData,
            images: imgData,
          };
          console.log("更新", data);
          const res = await update(data);
          console.log("更新结果", res);
          if (res.code == 200) {
            this.$message.success("修改成功");
            this.closeDialog();
            this._getWomanMeetList();
          }
        } else {
          let imgData = [];
          this.uploadImglist.forEach((item) => {
            imgData.push({
              dataTyp: "",
              dataDetailed: item.url,
            });
          });
          const data = {
            dataModule: "fnysh",
            dataTyp: "fnysh-yxal",
            ...this.updateMapData,
            images: imgData,
          };
          console.log(data);
          // return
          const res = await add(data);
          console.log("新增结果", res);
          if (res.code == 200) {
            this.$message.success("添加成功");
            this.closeDialog();
            this._getWomanMeetList();
          }
        }
      }
    },
    // 重置
    onReset() {
      this.yxalQuery.dataName = "";
      this._getWomanMeetList();
    },
    refresh() {
      this.$router.go(0); //刷新当前页面
    },
    goBack() {
      this.$router.go(-1);
    },
    // 图片上传成功
    imgsuccess(res, file) {
      if (res.code == 200) {
        // this.imageUrl = URL.createObjectURL(file.raw);
        // this.uploadImglist.push(res.fileName)
        this.uploadImglist.push({
          url: res.fileName,
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
      console.log(this.uploadImglist);
      this.loading.close();
    },
    handleRemove(file, fileList) {
      this.uploadImglist = [];
      fileList.forEach((item) => {
        this.uploadImglist.push(item);
      });
    },
    // 上传失败
    imgerror() {
      this.$message({
        type: "error",
        message: "上传失败",
      });
      this.loading.close();
    },
    // 上传中
    beforeAvatarUpload(file) {
      this.loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      // const isLt2M = file.size / 1024 < 150;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG/PNG 格式");
      } 
      // else if (!isLt2M) {
      //   this.$message.error("上传图片大小不能超过 150kb");
      // }
      this.loading.close();
      // return isJPG && isLt2M;
      return isJPG;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`最多只能上传10张图片`);
    },
    // 判断是否登录
    islogin() {
      // 1为编辑，2为阅读，0为未登录
      const pass = this.$cookies.get("pass");
      let returndata = 0;
      if (pass == this.readPass || pass == this.editPass) {
        if (this.existSystem()) {
          returndata = 1;
        } else {
          returndata = 2;
        }
      } else {
        returndata = 0;
      }
      return returndata;
    },
  },
  mounted() {
    this.loading = this.$loading({
      lock: true,
      text: "加载中...",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    this._getWomanMeetList();
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
